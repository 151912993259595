//Library Imports
import styled from "styled-components";

// CSS Imports
import "./global.css";

// CSS-in-JS
export const OrangePillsBtn = styled.button`
  font-family: Roboto;
  width: ${({ width }) => width || "70px"};
  height: ${({ height }) => height || "30px"};
  border: 1px solid #f2651b;
  border-radius: 12px;
  background-color: #fff6f3;
  color: var(--text-color-halloween-orange);
  font-size: var(--size11);
  font-weight: var(--fw700);
  line-height: var(--lh18);
  text-align: center;
  outline: none;
  padding-left: 5px;
  padding-right: 5px;
`;
