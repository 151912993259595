//Library Imports
import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { Fade } from "react-awesome-reveal";

// CSS Imports
import "./global.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import { BlackBtn } from "./BlackBtn";
import { OrangeOutlineBtn } from "./OrangeOutlineBtn";
import { userTrack } from "../Common";
import getConfig from "next/config";

// CSS-in-JS
const MainWrapper = styled.section`
  height: 613px;
  background: var(--bg-color-old-lace);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 71px;
  text-align: center;
  color: var(--text-color-black);
  width: 848px;
  margin: 0 auto;
  margin-bottom: 70px;

  @media screen and (max-width: 767px) {
    width: 287px;
    font-size: 30px;
    line-height: 50px;
  }
`;

const DualButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CTASection = React.memo((props) => {

  const { publicRuntimeConfig } = getConfig();


  const HandleFreeTrail = async () => {
    await userTrack("", "Onclick of Get started for free", "Platform");
    window.location.href = process.env.NODE_ENV == "development" ? "/Get-Started" : publicRuntimeConfig.assetPrefix + "/Get-Started.html";
  };
  const HandleContact = async () => {
    await userTrack("", "Onclick of contact sales", "Platform");
    window.location.href = process.env.NODE_ENV == "development" ? "/Contact-Sales" : publicRuntimeConfig.assetPrefix + "/Contact-Sales.html";
  };
  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <Container>
            <Row>
              <Col xl={12} lg={12} sm={12} xs={12}>
                <Fade
                  duration={1500}
                  fraction={0.5}
                  triggerOnce={true}
                >
                  <Title>
                    {props.text ? props.text : "Curious to learn more or want to get an estimate?"}
                  </Title>
                </Fade>
              </Col>
              <Col xl={12} lg={12} sm={12} xs={12}>
                <Fade duration={2500} fraction={0.5} triggerOnce={true}>
                  <DualButtonWrapper>
                    <BlackBtn
                      onClick={HandleFreeTrail}
                      width="241px"
                      height="56px"
                    >
                      Get Started for free
                    </BlackBtn>
                    <OrangeOutlineBtn
                      onClick={HandleContact}
                      width="234px"
                      height="56px"
                    >
                      Contact Sales
                    </OrangeOutlineBtn>
                  </DualButtonWrapper>
                </Fade>
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default CTASection;
