//Library Imports
import styled from "styled-components";

// CSS Imports
import "./global.css";

// CSS-in-JS
export const TransparentBtn = styled.button`
  font-family: Roboto;
  width: fit-content;
  height: ${({ height }) => height || "56px"};
  border-radius: 100px;
  background-color: transparent;
  color: var(--text-color-chinese-black);
  font-size: var(--size20);
  font-weight: var(--fw400);
  line-height: var(--lh23);
  text-align: center;
  outline: none;
  cursor: pointer;
  border: none;

  &::after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjI0NTEgMS44NzAxMlYxMS4zOTc1SDE2LjE5MDRWMy42NjMwOUw1LjI1Njg0IDE0LjU5NjdMNC41MTg1NSAxMy44MjMyTDE1LjQxNyAyLjkyNDhINy42ODI2MlYxLjg3MDEySDE3LjI0NTFaIiBmaWxsPSIjMTcxNzE3Ii8+Cjwvc3ZnPgo=");
    position: relative;
    top: 5px;
    left: 5px;
  }

  &:hover {
    color: var(--text-color-pumpkin);
  }
`;
