//Library Imports
import styled from "styled-components";

// CSS Imports
import "./global.css";

// CSS-in-JS
export const PillsTag = styled.button`
  font-family: Roboto;
  font-size: var(--size24);
  font-weight: var(--fw400);
  line-height: 33px;
  text-align: center;
  background-color: ${(props) => props.backgroundcolor || props.backgroundColor || "#dcfce7"};
  width: ${(props) => props.width || "250px"};
  height: ${(props) => props.height || "49px"};
  color: ${(props) => props.color || "#15803d"};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px;
  border-radius: 7px;
  box-sizing: border-box;

  span {
    /* font-weight: 700; */
    margin-left: 5px;
    
  }

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: var(--size16);
    width: fit-content;
    /* margin-top:120px; */
      width:250px;
  margin:auto;
    
  }

    /* @media (min-width: 576px) and (max-width: 1400px) {
      margin-left:40px;
    
      
} */
`;